<template>
  <div class="warp">
    <div class="header">
      <van-nav-bar
          title="我的笔记"
          left-arrow fixed
          @click-left="goBack"
      />
    </div>

    <div class="bodyWarp">
      <van-pull-refresh class="pullWarp" v-model="isLoading" @refresh="onRefresh" success-text="刷新完成">
        <!-- 释放提示 -->
        <template #pulling>
          <div>释放立即刷新</div>
        </template>

        <!-- 加载提示 -->
        <template #loading>
          <div>正在刷新</div>
        </template>

        <div v-for="item in list" :key="item.id" class="list" @click="detail(item)">
          <div class="noteItem leftConent">
            <div class="ask">{{item.content}}</div>
            <div class="answer">记录时间：{{item.intime}}</div>
          </div>
          <div class="noteItem">
            <img class="chapter_cirle-logo"  src="@/assets/all_Icon/drawable-xhdpi/icon_xiugai.png" />
          </div>
        </div>
      </van-pull-refresh>
    </div>

  </div>
</template>

<script>
import {noteList} from "@/api/study_course.js";
export default {
  data() {
    return {
      list: [],
      isLoading: false
    }
  },
  name: 'noteList',
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    onRefresh() {
      noteList({typeId:this.firstId}).then((res) => {
        if(res.data.code === 0) {
          this.list = res.data.data;
          this.isLoading = false;
        }
      });
    },
    detail(item) {
      window.localStorage.setItem('noteDetail',JSON.stringify(item));
      this.$router.push('noteDetail');
    }
  },
  created() {
    this.onRefresh();
  }
}
</script>

<style lang="less" scoped>
.header {
  font-size: 17px;
  font-weight: 500;
  color: #141414;
  line-height: 24px;
}

/deep/ .van-nav-bar__title {
  font-size: 17px;
  font-weight: 500;
  color: #141414;
}

/deep/ .header .van-icon-arrow-left {
  color: #000;
}

.bodyWarp {
  margin-top: 42px;
  height: calc(100vh - 46px);
  background: #F8F8F8;
  padding: 15px;

  .pullWarp{
    height: 100%;

    .list{
      background: #fff;
      margin-bottom: 15px;
      padding: 15px;

      .noteItem{
        display: inline-block;
      }
      .leftConent{
        width: calc(100% - 38px);  //宽度
      }

      .ask{
        margin-bottom: 11px;
        font-size: 14px;
        font-weight: 500;
      }
      .answer{
        min-height: 19px;
      }

      .chapter_cirle-logo{
        width: 15px;
        height: 15px;
      }
    }
  }
}

</style>
